<template>
    <v-container pa-0>
        <v-row justify="center" class="pb-3"><v-img :src="logoImg" max-height="50" max-width="100"
                contain></v-img></v-row>
        <v-row class="black" justify="center">
            <v-col cols="8" class="white--text">
                <h2>{{ quiz.quizTitle }}</h2>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" class="white--text"><span id="displayTimeSecond"></span></v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" v-if="this.quiz.allowQuizStop == true"><v-icon class="white--text"
                    @click="quizEnd()">highlight_off</v-icon></v-col>
            <!-- <v-col cols="2"><v-icon class="white--text" @click="quizEnd()">highlight_off</v-icon></v-col> -->
            <v-spacer></v-spacer>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" md="6">
                <v-form ref="form">
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col cols="9">{{ $t('question') }} {{ this.serialToShow }} of
                                    {{ this.quiz.questionsCount }}</v-col>
                                <v-col cols="3" v-if="this.eachQuestionMark > 0">{{ $t('marks') }}:
                                    {{ this.eachQuestionMark }}</v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="9">{{ $t('category') }}: {{ question.questionCategoryName }}</v-col>
                                <!-- <v-col cols="3">Lavel: {{question.questionLavelName}}</v-col> -->
                            </v-row>
                        </v-card-text>
                        <v-card-text class="black--text">
                            <h3 v-if="question.isCodeSnippet == true">
                                <pre>{{ question.questionDetail }}</pre>
                            </h3>
                            <h3 v-else>{{ question.questionDetail }}</h3>
                        </v-card-text>

                        <v-list v-if="question.questionTypeId == 1">
                            <v-list-item>
                                <v-list-item-action><v-checkbox v-model="responseA"></v-checkbox></v-list-item-action>
                                <v-list-item-content>{{ question.optionA }}</v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-action><v-checkbox v-model="responseB"></v-checkbox></v-list-item-action>
                                <v-list-item-content>{{ question.optionB }}</v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="question.optionC != '' && question.optionC != null">
                                <v-list-item-action><v-checkbox v-model="responseC"></v-checkbox></v-list-item-action>
                                <v-list-item-content>{{ question.optionC }}</v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="question.optionD != '' && question.optionD != null">
                                <v-list-item-action><v-checkbox v-model="responseD"></v-checkbox></v-list-item-action>
                                <v-list-item-content>{{ question.optionD }}</v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="question.optionE != '' && question.optionE != null">
                                <v-list-item-action><v-checkbox v-model="responseE"></v-checkbox></v-list-item-action>
                                <v-list-item-content>{{ question.optionE }}</v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-card-text v-else>
                            <v-textarea v-model="answerDescriptiveByUser" :label="$t('writeYourAnswerHere')" rows="5"
                                onpaste="return false" onCopy="return false" onCut="return false" auto-grow clearable>
                            </v-textarea>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn @click="submitAnswer(question)" class="black--text">{{ $t('submitAnswer') }}</v-btn>
                            <v-btn v-if="this.quiz.allowQuizSkip == true" @click="skipQuestion(question)"
                                class="black--text">{{ $t('skip') }}</v-btn>
                            <!-- <v-btn @click="skipQuestion(question)" class="black--text">{{ $t('skip') }}</v-btn> -->
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
            <v-col v-if="question.imagePath != '' || question.videoPath != ''" cols="12" md="6">
                <v-card>
                    <v-tabs>
                        <v-tab v-if="question.imagePath != ''"><v-icon>image</v-icon></v-tab>
                        <v-tab v-if="question.videoPath != ''"><v-icon>videocam</v-icon></v-tab>
                        <v-tab-item v-if="question.imagePath != ''">
                            <v-img :src="this.hostUrl + question.imagePath"></v-img>
                        </v-tab-item>
                        <v-tab-item class="iframe-container" v-if="question.videoPath != ''">
                            <iframe class="responsive-iframe" :src=question.videoPath title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                        </v-tab-item>
                    </v-tabs>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import config from '../../../public/config'
import i18n from '@/plugins/i18n'

export default {
    name: 'startQuiz',
    data() {
        return {
            quiz: {},
            interval: null,
            quizTime: null,
            questionSerial: null,
            question: {},
            hostUrl: null,
            answerDescriptiveByUser: '',
            responseA: false,
            responseB: false,
            responseC: false,
            responseD: false,
            responseE: false,
            userAnswer: '',
            serialToShow: null,
            eachQuestionMark: 0,
            pointerMoveCount: 0,
            switchTabCount: 0,
            browserName: '',
        }
    },
    methods: {
        startTimer(counter) {
            if (this.quiz.quizTime > 0) {
                var minutes = 0, seconds = 0;
                counter = counter * 60;
                this.interval = setInterval(() => {
                    minutes = parseInt(counter / 60, 10);
                    seconds = parseInt(counter % 60, 10);

                    minutes = minutes < 10 ? "0" + minutes : minutes;
                    seconds = seconds < 10 ? "0" + seconds : seconds;
                    document.getElementById('displayTimeSecond').innerHTML = minutes + ":" + seconds;
                    this.$store.dispatch('dashboard/saveQuizLiveTime', counter / 60);
                    if (counter == 0) {
                        const objTime = {
                            quizResponseInitialId: this.$store.getters['dashboard/responseInitial'].quizResponseInitialId,
                        }
                        this.$store.dispatch('dashboard/updateQuizTakenTime', objTime)
                        this.$router.push({ name: 'QuizResult' })
                    }
                    counter--;
                }, 1000)
            }
        },
        quizEnd() {
            this.$store.dispatch('dashboard/changeVisibility')
            this.$store.dispatch('dashboard/saveQuestionSerial', 1)
            if (this.quiz.quizMarkOptionId != 2) {
                this.$store.dispatch('dashboard/applyLoading')
                const objTimeUpdate = {
                    quizResponseInitialId: this.$store.getters['dashboard/responseInitial'].quizResponseInitialId,
                }
                this.$store.dispatch('dashboard/updateQuizTakenTime', objTimeUpdate)
                    .then((response) => {
                        if (response.status == 200) {
                            this.$store.dispatch('dashboard/cancelLoading')
                        }
                        this.$router.push({ name: 'QuizResult' })
                    })
                    .catch((err) => {
                        console.log(err)
                        this.$router.push({ name: 'NoInternet' })
                    })
            } else {
                this.$router.push({ name: 'QuizResult' })
            }
        },
        getRandomQuestions(quizId, randomFlag) {
            this.$store.dispatch('dashboard/applyLoading')
            const obj = {
                quizId: quizId,
                randomFlag: randomFlag
            }
            this.$store.dispatch('dashboard/fetchRandomQuestion', obj)
                .then((response) => {
                    this.$store.dispatch('dashboard/cancelLoading')
                    this.serials = response.data
                    this.questionSerial = this.serials[this.$store.getters['dashboard/questionSerial']]
                    this.getQuestion(this.questionSerial)
                })
                .catch((err) => {
                    this.$router.push({ name: 'NoInternet' })
                    console.log(err)
                })
        },
        getQuestion(serial) {
            //this.serialToShow=serial
            this.serialToShow = this.$store.getters['dashboard/serialToShow']
            const objSendForQuestion = {
                quizId: this.quiz.quizTopicId,
                serial: serial
            }
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('dashboard/fetchSingleQuestion', objSendForQuestion)
                .then((response) => {
                    this.$store.dispatch('dashboard/cancelLoading')
                    this.question = response.data
                    this.eachQuestionMark = response.data.perQuestionMark
                })
                .catch((err) => {
                    console.log(err)
                    this.$router.push({ name: 'NoInternet' })
                })
        },
        resetResponse() {
            this.answerDescriptiveByUser = ''
            this.responseA = false
            this.responseB = false
            this.responseC = false
            this.responseD = false
            this.responseE = false
        },
        answer(question) {
            this.$store.dispatch('dashboard/applyLoading')
            this.resetResponse()
            const objAnswer = {
                quizResponseInitialId: this.$store.getters['dashboard/responseInitial'].quizResponseInitialId,
                quizQuestionId: question.quizQuestionId,
                questionDetail: question.questionDetail,
                imagePath: question.imagePath,
                videoPath: question.videoPath,
                userAnswer: this.userAnswer,
                isAnswerSkipped: this.userAnswer == '' ? true : false,
                correctAnswer: question.correctOption,
                answerExplanation: question.answerExplanation,
                questionMark: question.perQuestionMark,
                pointerLeaveCount: this.pointerMoveCount,
                visibilitychangeCount: this.switchTabCount,
                addedBy: parseInt(localStorage.getItem('loggedUserId'))
            }
            this.$store.dispatch('dashboard/createDetailResponse', objAnswer)
                .then((response) => {
                    this.$store.dispatch('dashboard/cancelLoading')
                    this.pointerMoveCount = 0
                    this.switchTabCount = 0
                    if (response.status == 200) {
                        if (this.serials[this.$store.getters['dashboard/questionSerial'] + 1] == null) {
                            this.$store.dispatch('dashboard/saveQuestionSerial', 0)
                            this.$store.dispatch('dashboard/saveSerialToShow', 1)
                            this.$router.push({ name: 'QuizResult' })
                        } else {
                            this.$store.dispatch('dashboard/saveSerialToShow', this.$store.getters['dashboard/serialToShow'] + 1)
                            var newSerial = this.serials[this.$store.getters['dashboard/questionSerial'] + 1]
                            this.getQuestion(newSerial)
                            this.$store.dispatch('dashboard/saveQuestionSerial', this.$store.getters['dashboard/questionSerial'] + 1)
                        }
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.$router.push({ name: 'NoInternet' })
                })
        },
        skipQuestion(question) {
            this.userAnswer = ''
            this.answer(question)
        },
        submitAnswer(question) {
            this.userAnswer = ''
            if (question.questionTypeId == 1) {
                if (this.responseA == true) {
                    this.userAnswer = question.optionA
                }
                if (this.responseB == true) {
                    if (this.userAnswer != '') {
                        this.userAnswer = this.userAnswer + '#####' + question.optionB
                    } else {
                        this.userAnswer = question.optionB
                    }
                }
                if (this.responseC == true) {
                    if (this.userAnswer != '') {
                        this.userAnswer = this.userAnswer + '#####' + question.optionC
                    } else {
                        this.userAnswer = question.optionC
                    }
                }
                if (this.responseD == true) {
                    if (this.userAnswer != '') {
                        this.userAnswer = this.userAnswer + '#####' + question.optionD
                    } else {
                        this.userAnswer = question.optionD
                    }
                }
                if (this.responseE == true) {
                    if (this.userAnswer != '') {
                        this.userAnswer = this.userAnswer + '#####' + question.optionE
                    } else {
                        this.userAnswer = question.optionE
                    }
                }
                if (this.responseA == false && this.responseB == false && this.responseC == false && this.responseD == false
                    && this.responseE == false) {
                    this.$root.$emit('message_from_parent', 'Empty answer!')
                } else if (this.quiz.allowMultipleInputByUser == false && this.userAnswer.split('#####').length > 1) {
                    this.$root.$emit('message_from_parent', 'Multiple answer not allowed!')
                }
                else {
                    this.answer(question)
                }
            } else {
                this.userAnswer = this.answerDescriptiveByUser != null ? this.answerDescriptiveByUser.trim() : ''
                if (this.userAnswer == '') {
                    this.$root.$emit('message_from_parent', 'Empty answer!')
                } else {
                    this.answer(question)
                }
            }
        },
        checkExamEnd() {
            if (this.quiz.quizTime > 0) {
                const objTime = {
                    quizResponseInitialId: this.$store.getters['dashboard/responseInitial'].quizResponseInitialId,
                }
                if (this.$store.getters['settings/tabChangeEndExam']) {
                    this.$store.dispatch('dashboard/updateQuizTakenTime', objTime)
                    this.$router.push({ name: 'QuizResult' })
                }
                this.$store.dispatch('dashboard/checkQuizTimeExpiration', objTime)
                    .then((response) => {
                        console.log('checkQuizTimeExpiration', response.data)
                        if (response.data) {
                            this.$store.dispatch('dashboard/updateQuizTakenTime', objTime)
                            this.$router.push({ name: 'QuizResult' })
                        }
                    })
            }
        },
        handleVisibilityChange() {
            this.checkExamEnd()
            if (document.hidden) {
                this.switchTabCount = this.switchTabCount + 1
            }
        },
        handlePointerLeave() {
            this.pointerMoveCount = this.pointerMoveCount + 1
        },
        handleBlur() {
            this.checkExamEnd()
            if (this.browserName == 'firefox') {
                this.pointerMoveCount = this.pointerMoveCount + 1
            }
        }
    },
    computed: {
        logoImg: function () {
            return this.$store.getters['settings/logoOnExamPage'] == true ? config.hostname + this.$store.getters['settings/logoPath'] : ''
        }
    },
    mounted() {
        this.startTimer(this.quizTime)
        document.addEventListener('visibilitychange', this.handleVisibilityChange)
        document.addEventListener('pointerleave', this.handlePointerLeave)
        document.addEventListener('blur', this.handleBlur)
    },
    beforeDestroy() {
        clearInterval(this.interval)
        this.$store.dispatch('dashboard/saveQuizFlag', false)
        this.$store.dispatch('dashboard/saveResultFlag', true)
        this.$store.dispatch('settings/resetRefreshCount')
        document.removeEventListener('visibilitychange', this.handleVisibilityChange)
        document.removeEventListener('pointerleave', this.handlePointerLeave)
        document.removeEventListener('blur', this.handleBlur)
    },
    created() {
        i18n.locale = (localStorage.getItem('language') == null || localStorage.getItem('language') == '') ? 'en' : localStorage.getItem('language')
        this.$store.dispatch('dashboard/changeVisibilityFalse')
        if (this.$store.getters['dashboard/quizFlag'] == false) {
            this.$router.push({ name: 'Dashboard' })
        }
        //console.log(localStorage.getItem('quizTime'))
        this.quizTime = this.$store.getters['dashboard/quizTime']
        this.quiz = this.$store.getters['dashboard/quiz']
        //console.log(this.quiz)
        this.getRandomQuestions(this.quiz.quizTopicId, this.quiz.allowQuestionSuffle)
        this.hostUrl = config.hostname
        this.browserName = this.$store.getters['dashboard/clientInfo'].browserName

        if (this.$store.getters['settings/endExam'] == true) {
            if (window.location.reload) {
                this.$store.dispatch('settings/changeRefreshCount')
                if (this.$store.getters['settings/refreshCount'] == 2) {
                    this.$router.push({ name: 'QuizResult' })
                }
            }
        }
    }
}
</script>

<style scoped>
.iframe-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%;
    /* 16:9 Aspect Ratio */
}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}
</style>