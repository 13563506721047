<template>
    <v-container class="px-0">
        <Message />
        <v-form ref="form">
            <v-row>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="name" :label="$t('name')" :rules="[rules.required]" clearable>
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field v-if="roleName == 'Admin'" v-model="email" :label="$t('email')"
                        :rules="[rules.required, rules.emailRules]">
                    </v-text-field>
                    <v-text-field v-else v-model="email" :label="$t('email')"
                        :rules="[rules.required, rules.emailRules]" readonly>
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="mobile" :label="$t('mobile')" clearable>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" md="4">
                    <v-menu v-model="birthMenu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateOfBirth" :label="$t('dateOfBirth')" prepend-icon="mdi-calendar"
                                readonly v-bind="attrs" v-on="on" clearable></v-text-field>
                        </template>
                        <v-date-picker v-model="dateOfBirth" @input="birthMenu = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-file-input accept="image/*" :label="$t('profilePicture')" prepend-icon="mdi-camera"
                        @change="onFileSelected" show-size>
                    </v-file-input>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-img :src="previewImage" max-height="100" max-width="150" contain>
                    </v-img>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="address" :label="$t('address')" clearable>
                    </v-text-field>
                </v-col>

            </v-row>
            <v-row>
                <v-col cols="12" sm="6" md="4">
                    <v-btn color="primary" @click="save">
                        {{ $t('save') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import Message from '../../components/common/Message'
import config from '../../../public/config'

export default {
    name: 'Profile',
    components: {
        Message
    },
    data() {
        return {
            rules: {
                required: value => !!value || this.$t('required'),
                emailRules: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('emailInvalid')
            },
            birthMenu: false,
            imagePath: '',
            name: '',
            mobile: '',
            email: '',
            address: '',
            dateOfBirth: null,
            previewImage: null,
            selectedFile: null,
        }
    },
    methods: {
        initialize() {
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('user/fetchSingleUser', parseInt(localStorage.getItem('loggedUserId')))
                .then(response => {
                    this.$store.dispatch('dashboard/cancelLoading')
                    this.name = response.data.fullName
                    this.email = response.data.email
                    this.mobile = response.data.mobile
                    this.address = response.data.address
                    this.dateOfBirth = response.data.dateOfBirth == null ? null : response.data.dateOfBirth.substr(0, 10)
                    this.previewImage = response.data.imagePath != '' ? config.hostname + response.data.imagePath : ''
                    this.imagePath = response.data.imagePath != '' ? response.data.imagePath : ''
                })
                .catch(err => {
                    console.log(err)
                    this.$router.push({ name: 'NoInternet' })
                })
        },
        onFileSelected(e) {
            if (e != null) {
                this.selectedFile = e
                const reader = new FileReader()
                reader.readAsDataURL(this.selectedFile)
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
            } else {
                this.selectedFile = null
                this.previewImage = null
                this.imagePath = ''
            }
        },
        updateUser() {
            this.$store.dispatch('dashboard/applyLoading')
            const objUserProfile = {
                userId: parseInt(localStorage.getItem('loggedUserId')),
                fullName: this.name,
                mobile: this.mobile,
                email: this.email,
                address: this.address,
                imagePath: this.imagePath,
                dateOfBirth: this.dateOfBirth,
                lastUpdatedBy: parseInt(localStorage.getItem('loggedUserId')),
            }
            this.$store.dispatch('user/updateUserProfile', objUserProfile)
                .then(response => {
                    this.$store.dispatch('dashboard/cancelLoading')
                    if (response.status == 200) {
                        this.$root.$emit('message_from_parent', this.$t(response.data.responseMsg))
                    } else {
                        this.$root.$emit('message_from_parent', this.$t(response.data.responseMsg))
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$router.push({ name: 'NoInternet' })
                })
        },
        save() {
            if (this.$refs.form.validate()) {
                if (this.selectedFile != null) {
                    let fd = new FormData()
                    fd.append('image', this.selectedFile)
                    this.$store.dispatch('user/uploadImage', fd)
                        .then(response => {
                            if (response.status == 200) {
                                this.imagePath = '/' + response.data.dbPath
                                this.updateUser()
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })
                } else {
                    this.updateUser()
                }
            }
        }
    },
    computed: {
        roleName() {
            return this.$store.getters['dashboard/userInfo'].roleName
        },
    },
    created() {
        this.initialize()
    }
}
</script>