<template>
    <v-dialog
        v-model="showUsageDialog"
        max-width="800"
        @click:outside="closePackageDialog()"
    >      
        <v-card>
            <Loading/>
            <v-toolbar
                dark                     
            >               
                <v-spacer></v-spacer>
                <v-toolbar-title>{{ $t('package') }}: {{ this.userInfo.planName }}</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-timeline align-top dense>
                <v-timeline-item small dark>
                    {{ $t('packageStartsAt') }} {{ this.userInfo.planStartDate }}
                </v-timeline-item>
                <v-timeline-item small color="purple">
                    {{ $t('youAreAllowed') }} {{ this.userInfo.assessmentCount }} {{ $t('different') }} {{ $t('assessments') }}. {{ $t('youAttend') }} {{ showIndidualQuizAttended }} {{ $t('ofThose') }}.
                </v-timeline-item>
                <v-timeline-item small color="cyan">
                    {{ $t('youAreAllowed') }} {{ $t('total') }} {{ this.userInfo.responseAllAssessmentCount }} {{ $t('assessments') }}. {{ $t('youAttend') }} {{ showTotalQuizAttended }} {{ $t('ofThose') }}.
                </v-timeline-item>
                <v-timeline-item small color="teal">
                    {{ $t('youCanAttendEachAssessment') }} {{ this.userInfo.responsePerAssessmentCount }} {{$t('times')}}.
                </v-timeline-item>
                <v-timeline-item small color="grey">
                    {{ $t('packageWillExpire') }} {{ this.userInfo.planExpiryDate }}
                </v-timeline-item>
            </v-timeline>
        </v-card>        
    </v-dialog>
</template>

<script>
import Loading from '../../components/common/Loading'

export default {
    name:'PackageUsageInfo',
    components:{
      Loading
    },
    data(){
        return{
            dialogInfo:false,
            userInfo:{},
            individualQuizAttended:'',
            totalQuizAttended:''
        }
    },
    methods:{
        closePackageDialog(){
            this.$store.dispatch('dashboard/switchShowPackageUsage',false)
        },
        getUsageInfo(){
            this.$store.dispatch('dashboard/applyLoading')
            const obj={
                userId:this.userInfo.userId,
                paymentId:this.userInfo.paymentId,
            }
            this.$store.dispatch('dashboard/fetchPackageUsage',obj)
            .then((response)=>{
                this.$store.dispatch('dashboard/cancelLoading')
                if(response.status==200){
                   //console.log(response.data)
                   this.individualQuizAttended=response.data.totalAssessmentAttend
                   this.totalQuizAttended=response.data.totalAssessmentResponse
                }
            })
            .catch((err)=>{
                this.$store.dispatch('dashboard/cancelLoading')
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
        }
    },
    computed:{
        showUsageDialog(){
            return this.$store.getters['dashboard/showPackageUsage']
        },
        showIndidualQuizAttended(){
            return this.individualQuizAttended
        },
        showTotalQuizAttended(){
            return this.totalQuizAttended
        },
    },
    watch:{
        showUsageDialog(){
            this.getUsageInfo()
        },
    },
    created(){
        this.userInfo=this.$store.getters['dashboard/userInfo']
        this.getUsageInfo()
    }
}
</script>
