<template>
    <v-container class="pa-0">
        <Message />
        <v-overlay :value="isUnlock" :opacity="opacity">
            <v-form ref="form">
                <v-container class="text-center">
                    <v-row justify="center">
                        <v-avatar><v-img :src=getImg></v-img></v-avatar>
                    </v-row>
                    <v-row>
                        <v-col cols="12"><v-text-field :rules="[rules.required]" v-model="password" type="password"
                                :label="$t('typePassword')" outlined></v-text-field></v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12"><v-btn outlined @click="cancelOverlay"><v-icon
                                    x-large>vpn_key</v-icon></v-btn></v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-overlay>
    </v-container>
</template>

<script>
import Message from '../../components/common/Message'
import config from '../../../public/config'

export default {
    name: 'CheckPassword',
    components: {
        Message
    },
    data() {
        return {
            opacity: 0.95,
            password: '',
            rules: {
                required: value => !!value || this.$t('required')
            }
        }
    },
    methods: {
        /* cancelOverlay() {
            if (this.$refs.form.validate()) {
                console.log(this.$store.getters['dashboard/userInfo'])
                if (this.password == this.$store.getters['dashboard/userInfo'].password) {
                    this.$store.dispatch('dashboard/clearOverlay')
                    this.password = ''
                } else {
                    this.$root.$emit('message_from_parent', this.$t('wrongPassword'))
                }
            }
        } */
        cancelOverlay() {
            if (this.$refs.form.validate()) {
                //console.log(this.$store.getters['dashboard/userInfo'])
                const credential = {
                    email: this.$store.getters['dashboard/userInfo'].email,
                    password: this.password
                }
                this.$store.dispatch('dashboard/checkPassword', credential)
                    .then((response) => {
                        if (response.status == 200) {
                            this.$store.dispatch('dashboard/clearOverlay')
                            this.password = ''
                        } else {
                            this.$root.$emit('message_from_parent', this.$t('wrongPassword'))
                        }
                    })
            }
        }
    },
    computed: {
        isUnlock: function () {
            return this.$store.getters['dashboard/overlay']
        },
        getImg: function () {
            return this.$store.getters['dashboard/userInfo'].imagePath == null ? '' : config.hostname + this.$store.getters['dashboard/userInfo'].imagePath
        },
    }

}
</script>