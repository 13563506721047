<template>
    <v-container class="px-0">
        <div v-if="showResult == false">
            <v-row justify="center">
                <h3>{{ $t('containsDescriptiveQuestions') }}</h3>
            </v-row>
        </div>
        <div v-else ref="quizResult">
            <v-row v-if="downloadVisible == true" justify="end" class="pr-3">
                <v-icon medium @click="generateResultPdf">picture_as_pdf</v-icon>
            </v-row>
            <v-row justify="center">
                <v-col cols="4" sm="3" md="2" class="font-weight-black">
                    <h3>{{ $t('resultSheet') }}</h3>
                </v-col>
            </v-row>
            <v-row class="pl-4">
                <v-col cols="12" sm="6" md="1">
                    <span class="font-weight-medium">{{ $t('student') }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <span>{{ this.name }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="1">
                    <span class="font-weight-medium">{{ $t('email') }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <span>{{ this.email }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="1">
                    <span class="font-weight-medium">{{ $t('title') }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <span>{{ this.quizTitle }}</span>
                </v-col>
            </v-row>
            <v-row class="pl-4">
                <v-col cols="12" sm="6" md="1">
                    <span class="font-weight-medium">{{ $t('timeTakenMin') }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <span>{{ this.timeTaken }} min</span>
                </v-col>
                <v-col cols="12" sm="6" md="1">
                    <span class="font-weight-medium">{{ $t('marks') }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <span>{{ this.quizMarks }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="1">
                    <span class="font-weight-medium">{{ $t('marksObtained') }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <span>{{ this.marksObtained }}</span>
                </v-col>
            </v-row>
            <v-row class="pl-4">
                <v-col cols="12" sm="6" md="1">
                    <span class="font-weight-medium">{{ $t('totalPointerIncident') }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <span>{{ pointerLeaveCountSumExamine }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="1">
                    <span class="font-weight-medium">{{ $t('totalExamWindowsLeft') }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <span>{{ visibilitychangeCountSumExamine }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="1">
                    <span class="font-weight-medium">{{ $t('totalPenalty') }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <span>{{ totalPanalty }}</span>
                </v-col>
            </v-row>
            <v-data-table :headers="headersResult" :items="itemsResult" disable-pagination hide-default-footer>
                <template v-slot:[`item.isAnswerSkipped`]="{ item }">
                    <span>{{ formatAnswerSkipped(item) }}</span>
                </template>
                <template v-slot:[`item.questionMark`]="{ item }">
                    <span>{{ formatQuestionMarks(item) }}</span>
                </template>
                <template v-slot:[`item.userObtainedQuestionMark`]="{ item }">
                    <span>{{ formatUserMarks(item) }}</span>
                </template>
                <template v-slot:[`item.correctAnswer`]="{ item }">
                    <span>{{ formatCorrectAnswer(item) }}</span>
                </template>

            </v-data-table>
        </div>
    </v-container>
</template>

<script>
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

export default {
    name: 'quizResult',
    data() {
        return {
            headers: [
                { text: this.$t('question'), value: 'questionDetail' },
                { text: this.$t('yourAnswer'), value: 'userAnswer' },
                { text: 'Correct Answer', value: 'correctAnswer' },
                { text: this.$t('skipped'), value: 'isAnswerSkipped' },
                { text: this.$t('questionMarks'), value: 'questionMark' },
                { text: this.$t('yourMarks'), value: 'userObtainedQuestionMark' },
                { text: this.$t('pointerLeaveCount'), value: 'pointerLeaveCount' },
                { text: this.$t('visibilitychangeCount'), value: 'visibilitychangeCount' },
                { text: this.$t('answerExplanation'), value: 'answerExplanation' },
            ],
            name: '',
            email: '',
            quizTitle: '',
            timeTaken: '',
            quizMarks: '',
            marksObtained: '',
            itemsResult: [],
            allowCorrectOption: null,
            isShownResult: false,
            visibilitychangeCountSum: 0,
            pointerLeaveCountSum: 0
        }
    },
    methods: {
        generateResultPdf() {
            var pdfRef = this.$refs.quizResult;
            html2canvas(pdfRef).then(canvas => {
                var imgData = canvas.toDataURL('image/png');
                var doc = new jsPDF({
                    orientation: 'p'
                });
                var imgWidth = 210;
                var pageHeight = 295;
                var imgHeight = canvas.height * imgWidth / canvas.width;
                var heightLeft = imgHeight;
                var position = 10;

                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;

                while (heightLeft >= 0) {
                    position += heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }
                doc.save('Result.pdf');
            })
        },
        quizResult(id) {
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('dashboard/fetchFinishedExamInfo', id)
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.isExamined) {
                            this.isShownResult = true
                            this.$store.dispatch('dashboard/fetchFinishedExamResult', id)
                                .then((response) => {
                                    this.$store.dispatch('dashboard/cancelLoading')
                                    this.itemsResult = response.data

                                    this.name = this.itemsResult[0].fullName
                                    this.email = this.itemsResult[0].email
                                    this.quizTitle = this.itemsResult[0].quizTitle
                                    this.timeTaken = this.itemsResult[0].timeTaken
                                    this.pointerLeaveCountSum = this.itemsResult[0].pointerLeaveCountSum
                                    this.visibilitychangeCountSum = this.itemsResult[0].visibilitychangeCountSum
                                    this.quizMarks = this.itemsResult[0].quizMark == 0 ? 'n/a' : this.itemsResult[0].quizMark
                                    this.marksObtained = this.itemsResult[0].quizMark == 0 ? 'n/a' : (this.itemsResult[0].userObtainedQuizMark > this.itemsResult[0].quizMark ? this.itemsResult[0].quizMark : this.itemsResult[0].userObtainedQuizMark)
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        } else {
                            this.$store.dispatch('dashboard/cancelLoading')
                            this.isShownResult = false
                        }
                    } else {
                        this.$store.dispatch('dashboard/cancelLoading')
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.$router.push({ name: 'NoInternet' })
                })
        },
        formatAnswerSkipped(item) {
            return item.isAnswerSkipped == true ? 'yes' : 'no'
        },
        formatQuestionMarks(item) {
            return item.questionMark == 0 ? 'n/a' : item.questionMark
        },
        formatUserMarks(item) {
            return item.questionMark == 0 ? 'n/a' : item.userObtainedQuestionMark
        },
        formatCorrectAnswer(item) {
            return item.correctAnswer != '' ? item.correctAnswer.replace(/#####/g, ',') : ''
        },
    },
    computed: {
        headersResult() {
            return this.allowCorrectOption == true ? this.headers : this.headers.filter(function (item) {
                return item.text != 'Correct Answer'
            })
        },
        downloadVisible() {
            return window.innerWidth >= 960 ? true : false
        },
        showResult() {
            return this.isShownResult
        },
        pointerLeaveCountSumExamine: function () {
            return this.pointerLeaveCountSum
        },
        visibilitychangeCountSumExamine: function () {
            return this.visibilitychangeCountSum
        },
        totalPanalty: function () {
            return this.visibilitychangeCountSum + this.pointerLeaveCountSum
        }
    },
    mounted() {
        if (this.$store.getters['dashboard/resultFlag'] == false) {
            this.$router.push({ name: 'Dashboard' })
        }
    },
    beforeDestroy() {
        this.$store.dispatch('dashboard/saveResultFlag', false)
    },
    created() {
        //this.$store.dispatch('dashboard/changeVisibility')
        if (this.$store.getters['dashboard/quiz'].allowExamOnNewWindow) {
            this.$store.dispatch('dashboard/changeVisibilityFalse')
        } else {
            this.$store.dispatch('dashboard/changeVisibility')
        }
        this.allowCorrectOption = this.$store.getters['dashboard/quiz'].allowCorrectOption
        this.quizResult(this.$store.getters['dashboard/responseInitial'].quizResponseInitialId)
    }
}
</script>